import { Pane } from "evergreen-ui";
import AdsReport from "./AdsReport";

function App() {
  return (
    <Pane display="flex" padding={2}>
      <AdsReport />
    </Pane>
  );
}

export default App;
