import { Heading, Link, Pane, Text } from "evergreen-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { SheetRow } from "../AdsReport";

const SwiperContainerStyle: React.CSSProperties = {
  display: "flex",
  // width: "95vw",
  minHeight: "70vmin",
  margin: "30px auto 0",
  padding: "12px 12px 30px",
  border: "1px solid grey",
  borderRadius: "15px",
};

const SwiperSlideStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: "100%",
  overflow: "hidden",
};

export interface PostsSwiperProps {
  sheetRows: SheetRow[];
  dateStart: Date | null;
  dateEnd: Date | null;
}

export const PostsSwiper = (props: PostsSwiperProps) => {
  if (props.sheetRows.length === 0) {
    return <></>;
  }

  const sheetRows = props.sheetRows.filter((row) => {
    if (props.dateStart && props.dateEnd) {
      const rowStartDate = new Date(row.start);
      if (rowStartDate.getTime() < props.dateStart.getTime()) return false;
      if (rowStartDate.getTime() > props.dateEnd.getTime()) return false;
      return true;
    }
  });

  return (
    <Pane style={SwiperContainerStyle}>
      {sheetRows.length === 0 ? (
        <Text size={500} textAlign="center">
          Нет постов в данный период или период не выбран
        </Text>
      ) : (
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{
            type: "fraction",
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {sheetRows.map((row) => {
            const telegramLink = `${row.original_link}?embed=1`;
            const facebookLink = `https://www.facebook.com/plugins/post.php?href=${row.post_link}`;

            return (
              <SwiperSlide style={SwiperSlideStyle}>
                <Heading size={800} marginBottom={24} textAlign="center">
                  {row.title}
                </Heading>

                <Text size={500}>
                  Ссылка телеграм:
                  <Link href={row.original_link}>{row.original_link}</Link>
                </Text>
                <Text size={500}>
                  Ссылка facebook:
                  <Link href={row.post_link}>{row.post_link}</Link>
                </Text>
                <Text size={500}>Reach: {row.reach}</Text>
                <Text size={500}>Количество лайков: {row.reactions}</Text>
                <Text size={500}>Количество комментариев: {row.comments}</Text>
                <Text size={500}>
                  Импрешены (Количество показов): {row.impression}
                </Text>
                <Text size={500}>Дата начала показа рекламы: {row.start}</Text>
                <Text size={500}>
                  Дата остановки показа рекламы: {row.finish}
                </Text>

                <Pane
                  display="flex"
                  justifyContent="space-between"
                  marginTop={24}
                >
                  <Pane width="45%" height="100%">
                    <iframe
                      id="telegram-post-telegram-83"
                      src={telegramLink}
                      width="100%"
                      height="100%"
                      style={{
                        overflow: "hidden",
                        border: "none",
                        height: "60vh",
                      }}
                    ></iframe>
                  </Pane>
                  <Pane width="45%" height="100%">
                    <iframe
                      src={facebookLink}
                      style={{
                        border: "none",
                        overflow: "hidden",
                        height: "60vh",
                      }}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </Pane>
                </Pane>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Pane>
  );
};
