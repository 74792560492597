import { Pane, Spinner, Text, TextInput, Button } from "evergreen-ui";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import axios from "axios";
import { PostsSwiper } from "./components/PostsSwiper";
import "react-datepicker/dist/react-datepicker.css";

const AUTH_STORAGE = "auth_app_data";

const LoaderStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  minHeight: "100vh",
};

export type SheetRow = {
  status: string;
  post_name: string;
  title: string;
  original_link: string;
  post_link: string;
  reach: string;
  comments: string;
  reactions: string;
  impression: string;
  start: string;
  finish: string;
};

function getFirstDayOfMonth(year: number, month: number) {
  return new Date(year, month, 1);
}
function getLastDayOfMonth(year: number, month: number) {
  return new Date(year, month + 1, 0);
}

const today = new Date();
const firstDayCurrentMonth = getFirstDayOfMonth(
  today.getFullYear(),
  today.getMonth()
);
const lastDayCurrentMonth = getLastDayOfMonth(
  today.getFullYear(),
  today.getMonth()
);

export default function AdsReport() {
  const [sheetRows, setSheetRows] = useState<SheetRow[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dateStart, setDateStart] = useState<Date | null>(firstDayCurrentMonth);
  const [dateEnd, setDateEnd] = useState<Date | null>(lastDayCurrentMonth);

  const [authed, setAuthed] = useState<boolean>(false);
  const [login, setLogin] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const checkAuthToken = () => {
    const token = localStorage.getItem(AUTH_STORAGE);
    if (!token) {
      return false;
    }
    const tokenUrl = "https://ads-report.webtm.ru/server/token.php";
    axios
      .post(tokenUrl, {
        token: token,
      })
      .then((response) => {
        if (response.data) {
          setAuthed(true);
        } else {
          localStorage.removeItem(AUTH_STORAGE);
        }
      });
  };

  const auth = () => {
    const authUrl = "https://ads-report.webtm.ru/server/auth.php";
    setLogin("");
    setPassword("");
    axios
      .post(authUrl, {
        login: login,
        password: password,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem(AUTH_STORAGE, response.data);
          setAuthed(true);
        }
      });
  };

  const logout = () => {
    localStorage.removeItem(AUTH_STORAGE);
    setAuthed(false);
  };

  useEffect(() => {
    checkAuthToken();

    const apiUrl = "https://ads-report.webtm.ru/server/sheets.php";
    // const apiUrl = "http://localhost:8888/sheets.php";
    axios.get(apiUrl).then((response) => {
      if (response) {
        const rows: SheetRow[] = [];
        const sheetResponse = response.data.values;
        const filteredSheets = sheetResponse.filter(
          (row: string[], index: number) => {
            if (index < 2) return true;
            return row[0] !== "";
          }
        );
        filteredSheets.forEach((row: string[], index: number) => {
          if (index < 2) return true;
          const sheetRow: SheetRow = {
            status: row[0],
            post_name: row[1],
            title: row[2],
            original_link: row[3],
            post_link: row[4],
            reach: row[5],
            comments: row[6],
            reactions: row[7],
            impression: row[8],
            start: row[10],
            finish: row[11],
          };
          rows.push(sheetRow);
        });
        setSheetRows(rows);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <Pane style={LoaderStyle} padding={4}>
        <Spinner />
      </Pane>
    );
  }

  if (!authed) {
    return (
      <Pane padding={20} width="100%">
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          position="relative"
          zIndex="100"
        >
          <TextInput
            name="login"
            placeholder="Логин"
            marginBottom={12}
            marginTop={40}
            value={login}
            onChange={(e: any) => setLogin(e.target.value)}
          />
          <TextInput
            type="password"
            name="password"
            placeholder="Пароль"
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <Button marginTop={16} onClick={auth}>
            Войти
          </Button>
        </Pane>
      </Pane>
    );
  }

  return (
    <Pane padding={20} width="100%">
      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Pane
          display="flex"
          flexDirection="column"
          justifyContent="center"
          position="relative"
          zIndex="100"
        >
          {/* <Text size={600} marginBottom={12}>
            Период, в который компания была начата:
          </Text> */}
          <Pane
            display="flex"
            justifyContent="center"
            position="relative"
            zIndex="100"
          >
            <Text size={500}>От:</Text>
            <DatePicker
              selected={dateStart}
              onChange={(date) => setDateStart(date)}
            />
            <Text size={500} marginLeft={24}>
              До:
            </Text>
            <DatePicker
              selected={dateEnd}
              onChange={(date) => setDateEnd(date)}
            />
          </Pane>
        </Pane>

        <Button onClick={logout}>Выйти</Button>
      </Pane>

      <PostsSwiper
        sheetRows={sheetRows}
        dateStart={dateStart}
        dateEnd={dateEnd}
      />
    </Pane>
  );
}
